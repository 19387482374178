import { useEffect, useRef, useState } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { trans } from '../translations/translate'

export type CookiesConfig = {
  necessary: boolean
  preferences: boolean
  utc: string
  accepted: boolean
}

export const CookieDisclaimer = () => {
  const [showDisclaimer, setShowDisclaimer] = useState(false)
  const [showDropdown, setShowDropdown] = useState(false)
  const [cookies, setCookies] = useState<CookiesConfig>({
    necessary: false,
    preferences: false,
    utc: '',
    accepted: false
  })

  const acceptButtonRef = useRef<HTMLButtonElement>(null)
  const preferencesCheckboxRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const cookiesConfig = localStorage.getItem('cookiesConsent')
    if (cookiesConfig) {
      setCookies(JSON.parse(cookiesConfig))
    }
  }, [])

  useEffect(() => {
    if (cookies) {
      const { accepted } = cookies
      if (accepted) {
        setShowDisclaimer(false)
      } else {
        setShowDisclaimer(true)
      }
    }
  }, [cookies])

  const handleAcceptAll = () => {
    const cookiesConfig = {
      necessary: true,
      preferences: true,
      utc: new Date().toUTCString(),
      accepted: true
    }
    setShowDisclaimer(false)
    setCookies(cookiesConfig)
    localStorage.setItem('cookiesConsent', JSON.stringify(cookiesConfig))
  }

  const handleAcceptCustom = () => {
    const cookiesConfig = {
      necessary: true,
      preferences: cookies?.preferences || false,
      utc: new Date().toUTCString(),
      accepted: true
    }
    setShowDisclaimer(false)
    setCookies(cookiesConfig)
    localStorage.setItem('cookiesConsent', JSON.stringify(cookiesConfig))
  }

  const handleCustomCookiesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target
    setCookies({
      ...cookies,
      [name]: checked
    })
  }

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown)
    preferencesCheckboxRef.current?.focus()
  }

  if (!showDisclaimer) {
    return null
  }

  return (
    <>
      {showDisclaimer && <div className="fixed top-0 left-0 right-0 bottom-0 z-50 bg-black opacity-75" />}
      <section
        className="fixed bottom-0 left-0 right-0 flex flex-col items-center justify-center bg-gray-900 p-4 py-8 text-white"
        style={{ zIndex: 999 }}
        aria-label={trans('aria_label.cookie_disclaimer')}>
        <span className="flex w-full max-w-2xl items-center justify-between">
          <p className="inline-block text-lg font-bold text-white">🍪{' '}{trans('cookie_disclaimer.title')}</p>
        </span>
        <p className="mt-2 max-w-2xl text-sm text-white">{trans('cookie_disclaimer.description')}</p>
        {showDropdown && (
          <div className="mt-6 flex max-w-2xl flex-col gap-4 rounded bg-gray-800 p-4 shadow">
            <h2 className="w-full text-base font-bold">{trans('cookie_disclaimer.configure')}</h2>
            <div className="flex flex-wrap gap-2">
              <div className="flex">
                <input
                  type="checkbox"
                  className="mr-4 mt-1 h-4 w-4 min-w-[1rem] accent-primary-orange"
                  disabled
                  checked={cookies.necessary || true}
                  onChange={handleCustomCookiesChange}
                  name="technical"
                  id="technical"
                />
                <div>
                  <label className="w-[200px] text-sm font-bold text-white" htmlFor="personalization">
                    {trans('cookie_disclaimer.necessary')}
                  </label>
                  <p className="text-xs text-gray-400">{trans('cookie_disclaimer.necessary_description')}</p>
                </div>
              </div>
              <div className="flex">
                <input
                  type="checkbox"
                  className="mr-4 mt-1 h-4 w-4 min-w-[1rem] accent-primary-orange"
                  checked={cookies.preferences}
                  onChange={handleCustomCookiesChange}
                  name="preferences"
                  id="preferences"
                  ref={preferencesCheckboxRef}
                />
                <div>
                  <label className="w-[200px] text-sm font-bold text-white" htmlFor="personalization">
                    {trans('cookie_disclaimer.preferences')}
                  </label>
                  <p className="text-xs text-gray-400">{trans('cookie_disclaimer.preferences_description')}</p>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="mt-2 flex w-full max-w-2xl flex-col items-center justify-between md:flex-row">
          <button
            className="mt-2 flex w-full items-center justify-center rounded bg-white px-4 py-2 text-base font-bold text-gray-900 md:w-auto"
            onClick={toggleDropdown}
            aria-describedby={trans('aria_label.cookie_disclaimer_personalize')}>
            {trans('cookie_disclaimer.personalize')}{' '}
            {showDropdown ? <ChevronUpIcon className="ml-2 h-6 w-6" /> : <ChevronDownIcon className="ml-2 h-6 w-6" />}
          </button>
          {showDropdown
            ? (
            <button
              className="mt-2 flex w-full items-center justify-center rounded bg-white px-4 py-2 text-base font-bold text-gray-900 md:w-auto"
              onClick={handleAcceptCustom}
              aria-labelledby="cookie-disclaimer"
              aria-describedby={trans('aria_label.cookie_disclaimer_button_custom')}>
              {trans('cookie_disclaimer.continue')}
            </button>
              )
            : (
            <button
              ref={acceptButtonRef}
              className="mt-2 flex w-full items-center justify-center rounded bg-white px-4 py-2 text-base font-bold text-gray-900 md:w-auto"
              onClick={handleAcceptAll}
              aria-labelledby="cookie-disclaimer"
              aria-describedby={trans('aria_label.cookie_disclaimer_button_all')}>
              {trans('cookie_disclaimer.accept_all')}
            </button>
              )}
        </div>
      </section>
    </>
  )
}
