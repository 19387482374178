import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import { es, en } from './resources'
import LanguageDetector from 'i18next-browser-languagedetector'
import XHR from 'i18next-http-backend'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  es: {
    translation: es
  },
  en: {
    translation: en
  }
}

const DEFAULT_LANGUAGE = 'es'

i18next
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: { order: ['localStorage', 'navigator', 'htmlTag'] },
    resources: resources,
    // lng: DEFAULT_LANGUAGE, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: DEFAULT_LANGUAGE,
    keySeparator: '.',
    supportedLngs: ['en', 'es'],
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export default i18next
