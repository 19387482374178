import { Typography } from '@mui/material'
import PlacesInput from '../components/Home/PlacesInput'
import t from '../translations/translate'
import { Fragment, lazy, useEffect, Suspense } from 'react'
import { Footer } from '../components/Common/Footer'
import { LanguageSelector } from '../components/Home/LanguageSelector'
import { LMDescription } from '../components/Home/LastMinuteDescription'
import { useDispatch } from 'react-redux'
import { setRadius as setFilterRadius, setDate as setFilterDate } from '../redux/slices/searchFilterSlice'

const Carousel = lazy(() => import('../components/Home/Carousel'))

const busImages = [
  {
    src: 'images/canary_shuttle_bus_1.webp',
    alt: 'Image 1: Image of a CanaryShuttle bus',
    title: 'Image 1: CanaryShuttle bus'
  },
  {
    src: 'images/canary_shuttle_bus_4.webp',
    alt: 'Image 4: Image of a CanaryShuttle bus',
    title: 'Image 4: CanaryShuttle bus'
  }
]

function Home() {
  const RADIUS_DEFAULT = 5
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setFilterRadius(RADIUS_DEFAULT))
    dispatch(setFilterDate(new Date()))
  }, [])

  return (
    <Fragment>
      <main className="mt-4 flex w-full flex-col items-center justify-center">
        <h1>{t('home.title')}</h1>
        <section className="flex w-full justify-center px-8 py-4 md:w-[90vw] md:py-12">
          <span className="flex w-full flex-col items-center justify-center md:w-[60vw]">
            <h2 className="text-center text-xl font-bold">{t('home.book_now')}</h2>
            <PlacesInput />
            <Typography align="center" variant="h2" sx={{ padding: 2, px: 4, color: 'text.primary', fontSize: '1rem' }}>
              {t('home.subtitle')}
            </Typography>
          </span>
        </section>
        <section className="w-full rounded-t-3xl bg-gray-200 px-2 py-12 md:px-8">
          <LMDescription />
        </section>
        <section className="flex w-full flex-row flex-wrap justify-around gap-4 rounded-br-3xl bg-primary-orange px-2 py-12 shadow-md md:px-8">
          <div className="mx-2 h-full w-full rounded-lg shadow-md md:m-0 md:w-[68%]">
            <Suspense>
              <Carousel images={busImages} />
            </Suspense>
          </div>
          <div className="mx-2 flex w-full flex-col justify-center rounded-lg bg-primary-light-gray px-4 py-8 shadow-lg md:m-0 md:w-[28%]">
            <h2 className="mb-4 text-2xl font-bold text-[#fde68a]">{t('index.about_us')}</h2>
            <p className="break-keep font-medium text-white">{t('index.about_us_description')}</p>
          </div>
        </section>
      </main>
      <Footer />
      <LanguageSelector />
    </Fragment>
  )
}

export default Home
