import { createSlice } from '@reduxjs/toolkit'

export const serviceResultsSlice = createSlice({
  name: 'counter',
  initialState: {
    services: []
  },
  reducers: {
    setServices: (state, action) => {
      state.services = action.payload
    }
  }
})

export const { setServices } = serviceResultsSlice.actions

export default serviceResultsSlice.reducer
