import t, { trans } from '../../../translations/translate'
import { useMediaQuery } from '@mui/material'
import { MenuMobile } from './MenuMobile'
import { MenuDesktop } from './MenuDesktop'

type MenuLink = {
  id: string
  label: JSX.Element
  path: string
  ariaLabel: string
}

export const menuLinks: { [key: string]: MenuLink } = {
  home: {
    id: 'menu-home',
    label: t('buttons.home'),
    path: '/',
    ariaLabel: trans('aria_label.main_menu.home')
  },
  searchBooking: {
    id: 'menu-search-booking',
    label: t('search_booking.title'),
    path: '/search-booking',
    ariaLabel: trans('aria_label.main_menu.search_booking')
  }
}

export const MainMenu = () => {
  const isMobile = useMediaQuery('(max-width:768px)')

  return <div className="absolute top-8 right-4 z-10">{isMobile ? <MenuMobile /> : <MenuDesktop />}</div>
}
