import t, { trans } from '../../translations/translate'

export const Footer = () => {
  return (
    <footer
      className="mt-8 flex w-[100%] flex-col items-center justify-center bg-gray-50 px-6 md:px-12"
      role="contentinfo"
      aria-label={trans('aria_label.footer')}>
      <div className="flex w-full flex-row flex-wrap items-center justify-center px-8 pt-6 pb-2  md:justify-between">
        <img
          loading="lazy"
          src="/images/funded/logo_funded_es_horizontal.webp"
          alt="Funded by the European Union"
          width={200}
          height={50}
          className="h-auto"
        />
        <img
          loading="lazy"
          src="/images/prtr/logo_prtr_3_color.webp"
          alt="Logo Plan de Recuperación, Transformación y Resiliencia"
          width={200}
          height={60}
          className="h-auto"
        />
      </div>

      <div className="flex w-full flex-row items-center justify-center">
        <div className="h-[1px] w-full bg-gray-300" />
      </div>

      <div className="flex w-full flex-col flex-wrap items-center justify-between gap-8 p-8 text-left md:p-12 lg:flex-row">
        <span className="flex w-full flex-col gap-2 px-4 text-sm lg:w-1/3">
          <span className="mb-2 flex flex-col">
            <p className="text-xl font-bold text-primary-orange">{t('legal.title')}</p>
          </span>
          <a
            href="https://canaryshuttle.com/en/general_info"
            target="_blank"
            rel="noreferrer"
            className="hover:text-primary-orange">
            {t('legal.general_info')}
          </a>
          <a
            href="https://canaryshuttle.com/files/privacy-policy-en.pdf"
            target="_blank"
            rel="noreferrer"
            className="hover:text-primary-orange">
            {t('legal.privacy_policy')}
          </a>
          <a
            href="https://canaryshuttle.com/files/cookies-policy-en.pdf"
            target="_blank"
            rel="noreferrer"
            className="hover:text-primary-orange">
            {t('legal.cookies_policy')}
          </a>
          <a
            href="https://canaryshuttle.com/files/general-terms-and-conditions-en.pdf"
            target="_blank"
            rel="noreferrer"
            className="hover:text-primary-orange">
            {t('legal.terms_and_conditions')}
          </a>
          <a
            href="https://canaryshuttle.com/en/complaints"
            target="_blank"
            rel="noreferrer"
            className="hover:text-primary-orange">
            {t('legal.complaints_and_claims')}
          </a>
        </span>
        <span className="flex w-full flex-col gap-2 px-4 text-sm lg:w-1/3">
          <span className="mb-2 flex flex-col">
            <p className="text-xl font-bold text-primary-orange">{t('contact')}</p>
          </span>
          <div className="grid grid-cols-8">
            <a href="https://goo.gl/maps/t7BSQFoYWc6Lc6yDA" target="_blank" rel="noreferrer" className="col-span-7">
              Canary Tourist Shuttle, S.L. Adelfas 32 Street
              <br />
              Las Palmas, España, 35118
            </a>
          </div>
          <div className="grid grid-cols-8">
            <span className="col-span-7">
              <a href="tel:+34928184608" target="_blank" rel="noreferrer">
                +34 928 184 608
              </a>
              /{' '}
              <a href="tel:+34650617296" target="_blank" rel="noreferrer">
                +34 650 617 296
              </a>
            </span>
          </div>
          <div className="grid grid-cols-8">
            <a href="mailto:reservas@grupo1844.es" target="_blank" rel="noreferrer" className="col-span-7">
              reservas@grupo1844.es
            </a>
          </div>
          <div className="grid grid-cols-8">
            <a href="https://www.canaryshuttle.com" target="_blank" rel="noreferrer" className="col-span-7">
              www.canaryshuttle.com
            </a>
          </div>
        </span>
      </div>
    </footer>
  )
}
