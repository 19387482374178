import { Trans } from 'react-i18next'
import i18next from 'i18next'

type translateProps = Parameters<typeof Trans>[0]

export default function t(
  i18nKey: translateProps['i18nKey'],
  values?: translateProps['values']
) {
  const tags = {
    span: <span />,
    strong: <strong />
  }

  const count = values ? Object.entries(values).filter(([key, value]) => key === 'count')[0] : null
  const countVal = count && typeof count[1] === 'number' ? count[1] : 0

  return <Trans i18nKey={i18nKey} values={values} components={tags} count={countVal} />
}

export const trans = (key: string) => i18next.t(key)
