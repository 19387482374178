import { TicketIcon, MapIcon, ShieldCheckIcon, UserGroupIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

export const LMDescription = () => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-wrap" id="lm-description" role="region">
      <h2 className="text-3xl font-bold text-center w-full mb-4 text-primary-orange">
        {t('home.lm_description.title')}
      </h2>
      <div className="w-full py-4 md:w-1/3">
        <article className="mx-4 flex h-full flex-col items-center rounded-lg border border-gray-200 bg-white p-4 shadow-xl">
          <div className="mb-4 flex h-10 w-full items-center justify-center">
            <TicketIcon className="h-10 w-10 text-primary-orange" />
          </div>
          <h3 className="mb-2 text-center text-xl font-bold text-primary-orange">
            {t('home.lm_description.title_1')}
          </h3>
          <p className="text-base">
            {t('home.lm_description.description_1')}
          </p>
        </article>
      </div>
      <div className="w-full py-4 md:w-1/3">
        <article className="mx-4 flex h-full flex-col items-center rounded-lg border border-gray-200 bg-white p-4 shadow-xl">
          <div className="mb-4 flex h-10 w-full items-center justify-center">
            <MapIcon className="h-10 w-10 text-primary-orange" />
          </div>
          <h3 className="mb-2 text-center text-xl font-bold text-primary-orange">
            {t('home.lm_description.title_2')}
          </h3>
          <p className="text-base">
            {t('home.lm_description.description_2')}
          </p>
        </article>
      </div>
      <div className="w-full py-4 md:w-1/3">
        <article className="mx-4 flex h-full flex-col items-center rounded-lg border border-gray-200 bg-white p-4 shadow-xl">
          <div className="mb-4 flex h-10 w-full items-center justify-center">
            <ShieldCheckIcon className="h-10 w-10 text-primary-orange" />
          </div>
          <h3 className="mb-2 text-center text-xl font-bold text-primary-orange">
            {t('home.lm_description.title_3')}
          </h3>
          <p className="text-base">
            {t('home.lm_description.description_3')}
          </p>
        </article>
      </div>
      <div className="w-full py-4 hidden md:block md:w-1/3">
      </div>
      <div className="w-full py-4 md:w-1/3">
        <article className="mx-4 flex h-full flex-col items-center rounded-lg border border-gray-200 bg-white p-4 shadow-xl">
          <div className="mb-4 flex h-10 w-full items-center justify-center">
            <UserGroupIcon className="h-10 w-10 text-primary-orange" />
          </div>
          <h3 className="mb-2 text-center text-xl font-bold text-primary-orange">
            {t('home.lm_description.title_5')}
          </h3>
          <p className="text-base">
            {t('home.lm_description.description_5')}
          </p>
        </article>
      </div>
    </div>
  )
}
