import { UserIcon, TicketIcon, ArrowLeftOnRectangleIcon } from '@heroicons/react/24/outline'
import t, { trans } from '../../../translations/translate'
import { useContext, useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../../../contexts/AuthContext'
import { menuLinks } from './MainMenu'

export const AuthMenuDesktop = () => {
  const { logout, currentUserData } = useContext(AuthContext)
  const [showMenu, setShowMenu] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setShowMenu(false)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuRef])

  return (
    <div className="flex flex-col items-center justify-center">
      <button
        onClick={() => {
          setShowMenu(!showMenu)
        }}
        ref={buttonRef}
        aria-controls={showMenu ? 'menu-mobile' : undefined}
        aria-haspopup="true"
        aria-label={trans('aria_label.menu_button')}
        className="flex items-center justify-center">
        <UserIcon className=" mr-2 h-6 w-6 text-primary-orange" aria-label={trans('aria_label.user_icon')} />
        <span className="w-24 overflow-hidden overflow-ellipsis whitespace-nowrap text-base font-bold md:w-36">
          {currentUserData ? currentUserData.Name : ''}
        </span>
        <span className="sr-only">{t('buttons.menu')}</span>
      </button>
      {showMenu && (
        <div
          className="absolute z-10 flex w-40 flex-col items-center rounded-lg bg-white p-2 shadow-lg"
          style={{ top: '2.4rem', right: '0rem' }}
          ref={menuRef}>
          <Link
            to="/agencies/my-bookings"
            className="flex w-full p-2 text-left text-base hover:text-primary-orange md:text-lg">
            <TicketIcon className="mr-2 h-5 w-[20%] text-primary-orange" aria-label="menu mobile" />
            <span className="w-[90%] text-base">{t('my_bookings.title')}</span>
          </Link>
          <div className="mx-4 my-2 w-full border-t border-gray-300"></div>
          <button
            onClick={logout}
            className="flex w-full p-2 text-left text-base hover:text-primary-orange md:text-lg"
            aria-label={trans('aria_label.logout_button')}>
            <ArrowLeftOnRectangleIcon
              className="mr-2 h-5 w-[20%] text-primary-orange"
              aria-label={trans('aria_label.logout_icon')}
            />
            <span className="w-[90%] text-base">{t('auth.logout')}</span>
          </button>
        </div>
      )}
    </div>
  )
}

export const MenuDesktop = () => {
  const { isLoggedIn, authToken } = useContext(AuthContext)

  return (
    <div className="flex flex-row items-center justify-end">
      {Object.keys(menuLinks).map((key) => {
        const link = menuLinks[key]
        return (
          <div className="p-2 text-base hover:text-primary-orange md:text-lg" key={key}>
            <Link to={link.path} aria-label={link.ariaLabel} id={link.id}>
              {link.label}
            </Link>
          </div>
        )
      })}
      {isLoggedIn && authToken && (
        <>
          <div className="m-2 h-6 w-px bg-gray-300" />
          <AuthMenuDesktop />
        </>
      )}
    </div>
  )
}
