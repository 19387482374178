import { Link } from 'react-router-dom'
import { MainMenu } from './MainMenu'
import { AppBar, Toolbar } from '@mui/material'
import { trans } from '../../../translations/translate'

export default function Header() {
  return (
    <AppBar position="relative" color="transparent" elevation={1}>
      <Toolbar
        className="mx-0 mb-2 flex items-center justify-between md:mx-[4%] xl:mx-[8%]"
        id="header"
        role="heading"
        aria-level={1}
        aria-label={trans('aria_label.header')}>
        <Link to={'/'} className="p-1">
          <img src="/logo.png" alt="Canary Shuttle logo" width={280} height={100} />
        </Link>
        <MainMenu />
      </Toolbar>
    </AppBar>
  )
}
