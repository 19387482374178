import { createContext } from 'react'
import { UserData, useAuth } from '../hooks/useAuth'

type AuthContextData = {
  isLoggedIn: boolean
  authToken: string | null
  currentUserData: UserData | null
  isLoginLoading: boolean
  isLoginError: boolean
  login: (username: string, passcode: string)=> void
  logout: ()=> void
}

type AuthProviderProps = {
  children: React.ReactNode
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

export function AuthProvider({ children }: AuthProviderProps) {
  const { isLoggedIn, isLoginLoading, isLoginError, login, logout, authToken, currentUserData } = useAuth()

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        authToken,
        currentUserData,
        isLoginLoading,
        isLoginError,
        login,
        logout
      }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
