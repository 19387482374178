// eslint-disable-next-line no-use-before-define
import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { persistor, store } from './redux/store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import reportWebVitals from './reportWebVitals'
import App from './App'
import './index.css'
import { GOOGLE_API_KEY } from './utils/constants'
import i18n from './translations/i18n'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { CookiesConfig } from './components/CookieDisclaimer'

if (process.env.NODE_ENV !== 'production') {
  const axe = require('@axe-core/react')
  axe(React, ReactDOM, 1000)
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

// Load Google Maps library
const script = document.createElement('script')
script.type = 'text/javascript'
script.async = true
script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places&callback=Function.prototype`
document.body.appendChild(script)

document.documentElement.lang = i18n.language

// Remove language from local storage if cookies are not accepted or preferences are not set
const cookiesConsent = JSON.parse(localStorage.getItem('cookiesConsent') || '{}') as CookiesConfig
if (!cookiesConsent.accepted || !cookiesConsent.preferences) {
  localStorage.removeItem('i18nextLng')
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF5500'
    },
    secondary: {
      main: '#FF5500'
    }
  },
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(',')
  }
})

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <StrictMode>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <App />
            </LocalizationProvider>
          </ThemeProvider>
        </BrowserRouter>
      </StrictMode>
    </PersistGate>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
