import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { SearchFilter, Location } from '../../interfaces/interfaces'

const initialState: SearchFilter = {
  date: new Date(),
  radius: 5,
  latitude: 0,
  longitude: 0,
  address: ''
}

export const searchFilterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<SearchFilter>) => {
      state.date = action.payload.date
      state.radius = action.payload.radius
      state.latitude = action.payload.latitude
      state.longitude = action.payload.longitude
    },
    setDate: (state, action: PayloadAction<Date>) => {
      state.date = action.payload
    },
    setLocation: (state, action: PayloadAction<Location>) => {
      state.latitude = action.payload.latitude
      state.longitude = action.payload.longitude
      state.address = action.payload.address
    },
    setRadius: (state, action: PayloadAction<number>) => {
      state.radius = action.payload
    },
    clearFilter: (state) => {
      state = initialState
    }
  }
})

export const { setFilter, setDate, setLocation, setRadius } = searchFilterSlice.actions

export default searchFilterSlice.reducer
